import Url from './url.ts';
/**
 * 'none' - will not print anything to console  
 * 'errors' - will print only errors to console  
 * 'warnings' - will print errors and warnings to console  
 * 'all' - will print everything to console  
 */
type LoggingLevel = 'none' | 'errors' | 'warnings' | 'all';

const BROWSER_CONSOLE = window['console']; 

class O365Logger implements Console {
    static #isInternalConstructing: boolean;
    private static _instance: O365Logger;
    static getInstance() {
        if (!O365Logger._instance) {
            O365Logger.#isInternalConstructing = true;
            O365Logger._instance = new O365Logger();
            O365Logger.#isInternalConstructing = false;
        }
        return O365Logger._instance;
    }

    /**
     * 'none' - will not print anything to console  
     * 'errors' - will print only errors to console  
     * 'warnings' - will print errors and warnings to console  
     * 'all' - will print everything to console  
     */
    loggingLevel: LoggingLevel = 'none';

    constructor() {
        if (!O365Logger.#isInternalConstructing) {
            throw new TypeError('O365Logger is not constructable, use O365Logger.getInstance()');
        }
        this.loggingLevel = 'errors';
        import('o365-modules').then(x => {
            const app = x.app;
            const userSession = x.userSession;
            if (app?.isDebug) {
                this.loggingLevel = 'all';
            } else {
                try {
                    const level = Url.getParam('O365_LOGGING_LEVEL') as LoggingLevel | null;
                    if (level && ['none', 'errors', 'warnings', 'all'].includes(level)) {
                        this.loggingLevel = level;
                    } else if (userSession && userSession?.isDeveloper) {
                        this.loggingLevel = 'warnings'
                    }
                } catch (ex) {
                    this.loggingLevel = 'none';
                }
            }
        }).catch(ex => {
            this.error(ex);
        });
    }

    assert(condition?: boolean | undefined, ...data: any[]): void {
        this.withLevel('errors', () => BROWSER_CONSOLE.assert(condition, ...data));
    }
    clear(): void {
        BROWSER_CONSOLE.clear();
    }
    count(label?: string | undefined): void {
        this.withLevel('all', () => BROWSER_CONSOLE.count(label));
    }
    countReset(label?: string | undefined): void {
        this.withLevel('all', () => BROWSER_CONSOLE.countReset(label));
    }
    debug(...data: any[]): void {
        this.withLevel('all', () => BROWSER_CONSOLE.debug(...data));
    }
    dir(item?: any, options?: any): void {
        this.withLevel('all', () => BROWSER_CONSOLE.dir(item, options));
    }
    dirxml(...data: any[]): void {
        this.withLevel('all', () => BROWSER_CONSOLE.dirxml(...data));
    }
    error(...data: any[]): void {
        this.withLevel('errors', () => BROWSER_CONSOLE.error(...data));
    }
    group(...data: any[]): void {
        this.withLevel('all', () => BROWSER_CONSOLE.group(...data));
    }
    groupCollapsed(...data: any[]): void {
        this.withLevel('all', () => BROWSER_CONSOLE.groupCollapsed(...data));
    }
    groupEnd(): void {
        this.withLevel('all', () => BROWSER_CONSOLE.groupEnd());
    }
    info(...data: any[]): void {
        this.withLevel('all', () => BROWSER_CONSOLE.info(...data));
    }
    log(...data: any[]): void {
        this.withLevel('all', () => BROWSER_CONSOLE.log(...data));
    }
    table(tabularData?: any, properties?: string[] | undefined): void {
        this.withLevel('all', () => BROWSER_CONSOLE.table(tabularData, properties));
    }
    time(label?: string | undefined): void {
        this.withLevel('all', () => BROWSER_CONSOLE.time(label));
    }
    timeEnd(label?: string | undefined): void {
        this.withLevel('all', () => BROWSER_CONSOLE.timeEnd(label));
    }
    timeLog(label?: string | undefined, ...data: any[]): void {
        this.withLevel('all', () => BROWSER_CONSOLE.timeLog(label, ...data));
    }
    timeStamp(label?: string | undefined): void {
        this.withLevel('all', () => BROWSER_CONSOLE.timeStamp(label));
    }
    trace(...data: any[]): void {
        this.withLevel('all', () => BROWSER_CONSOLE.trace(...data));
    }
    warn(...data: any[]): void {
        this.withLevel('warnings', () => BROWSER_CONSOLE.warn(...data));
    }

    /** Execute a function if the current logging level meets the required minimum level */
    withLevel(pMinimumLevel: LoggingLevel, pFunction: () => void) {
        let execute = false;

        switch (this.loggingLevel) {
            case 'none':
                execute = false;
                break;
            case 'errors':
                execute = pMinimumLevel === 'errors';
                break;
            case 'warnings':
                execute = ['errors', 'warnings'].includes(pMinimumLevel);
                break;
            case 'all':
                execute = true
                break;
        }
        if (execute) {
            pFunction();
        }
    }
}

const logger = O365Logger.getInstance();

export default logger;